<!--
 * @Author: Link
 * @Date: 2021-01-09 16:14:01
 * @LastEditTime: 2021-01-26 11:51:03
-->
<template>
  <div>
    <el-card class="editContainer">
      <div class="searchContainer">
        <el-row :gutter="20" class="query">
          <el-col :span="8">
            <span class="searchText">公众号名称</span>
            <el-input class="searchInput" v-model.trim="searchInput" placeholder="公众号名称" clearable> </el-input>
          </el-col>
          <el-col :span="8" :offset="8">
            <el-button type="info" @click="resetSearch()">重置</el-button>
            <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
          >
        </el-row>
      </div>
      <div class="tableContainer">
        <div class="header">
          <span class="title">公众号列表</span>
          <el-button style="float: right;margin-left:10px" type="success" @click="handleAuth">获取授权二维码</el-button>
          <!-- <el-button style="float: right" type="success" @click="handleAdd">添加</el-button> -->
        </div>
        <el-table :data="table.list" style="margin-top:30px">
          <el-table-column
            align="center"
            v-for="col in Object.keys(table.label)"
            :key="col"
            :label="table.label[col]"
            :prop="col"
          >
            <template slot-scope="{ row }">
              <el-switch
                v-if="col === 'isDefault'"
                v-model="row.isDefault"
                @change="handleSwitchChange(row)"
              ></el-switch>
              <div v-else-if="col === 'isAuthorization'">
                {{ row.isAuthorization ? '已授权' : '未授权' }}
              </div>

              <div v-else>{{ row[col] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button @click="action('check', row)" type="text" size="small" c>查看</el-button>
              <el-button @click="action('related', row)" type="text" size="small">关联</el-button>
              <!-- <el-button @click="action('delete', row)" type="text" size="small" class="deleteBtnText">
                删除
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            class="pagination"
          >
          </el-pagination>
        </div>
      </div>

      <el-dialog
        :visible="dialog.show"
        :title="`${dialog.type === 'check' ? '查看' : '添加'}公众号`"
        @close="dialog.show = false"
        :close-on-click-modal="false"
      >
        <el-form label-width="150px" ref="form">
          <template v-for="key in Object.keys(dialog.field)">
            <el-form-item :key="key" :label="dialog.field[key].label + '：'" :required="dialog.field[key].required">
              <el-input
                :disabled="dialog.type === 'check'"
                style="max-width:200px"
                v-model.trim="dialog.field[key].value"
                v-if="key !== 'mpQrCodeUrl'"
              ></el-input>
              <bm-upload v-else v-model="dialog.field[key].value" :limit="1"></bm-upload>
            </el-form-item>
          </template>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialog.show = false">取 消</el-button>
          <el-button type="primary" v-if="dialog.type !== 'check'" @click="submit" :loading="dialog.acting"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 删除组件 -->
      <bm-deleteItem
        url="/boom-center-product-service/sysAdmin/wx/media/platform/"
        :timestamp="timestamp"
        @successDelete="successDelete"
      ></bm-deleteItem>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'

export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      searchInput: '',
      timestamp: 0,
      related: {
        show: false,
        id: ''
      },
      table: {
        label: {
          mpName: '公众号名称',
          mpAppId: '公众号开发者ID',
          mpOriginalId: '公众号原始ID',
          isDefault: '是否是默认跳转的公众号',
          isAuthorization: '是否授权'
        },
        list: []
      },
      dialog: {
        show: false,
        type: 'add',
        acting: false,
        id: '', //修改的id
        field: {
          mpAppId: {
            label: '公众号开发者ID',
            required: true,
            value: ''
          },
          mpName: {
            label: '公众号名称',
            required: true,
            value: ''
          },
          mpOriginalId: {
            label: '公众号原始ID',
            required: true,
            value: ''
          },
          mpServerSecret: {
            label: '开发者密码',
            required: true,
            value: ''
          },
          mpServerAesKey: {
            label: '消息加解密密钥',
            required: true,
            value: ''
          },
          mpServerToken: {
            label: '服务器配置令牌',
            required: true,
            value: ''
          },
          mpWechatId: {
            label: '公众号微信号',
            value: ''
          },
          mpQrCodeUrl: {
            label: '公众号二维码',
            value: ''
          },
          mpDescription: {
            label: '公众号介绍',
            value: ''
          },
          mpEmail: {
            label: '公众号登录邮箱',
            value: '',
            required: true
          }
        }
      },
      pagination: {
        total: 1,
        size: 10,
        page: 1
      }
    }
  },

  methods: {
    resetSearch() {
      this.searchInput = ''
      this.getList()
    },
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          name: this.searchInput
        }
      }
      this.$http
        .get(`/boom-center-wechat-service/sysAdmin/wx/media/platform`, params)
        .then(res => {
          this.table.list = res.records
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '获取列表错误')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleAuth() {
      this.$http
        .get('boom-center-wechat-service/sysAdmin/wx/media/platform/get_auth_url')
        .then(res => {
          window.open(res.replace('http:', '').replace('https:', ''))
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    handleAdd() {
      Object.keys(this.dialog.field).map(key => (this.dialog.field[key].value = ''))
      this.dialog.type = 'add'
      this.dialog.show = true
    },
    handleSwitchChange(row) {
      this.$http
        .put(`boom-center-wechat-service/sysAdmin/wx/media/platform/modifyDefault/${row.id}`)
        .then(res => {
          this.getList()
          this.$message.success('操作成功')
        })
        .catch(err => {
          this.message.error(err.msg)
        })
    },
    action(type, row) {
      if (type === 'check') {
        Object.keys(this.dialog.field).map(key => {
          this.$set(this.dialog.field[key], 'value', row[key])
        })
        this.dialog.id = row.id
        this.dialog.type = 'check'
        this.dialog.show = true
      }
      if (type === 'delete') {
        this.timestamp = new Date().getTime()
        sessionStorage['deleteItemId'] = row.id
      }
      if (type === 'related') {
        this.$router.push()

        this.$router.push({
          path: '/setting/platform/MediaPlatform/related',
          query: {
            id: row.id
          }
        })
      }
    },
    submit() {
      this.dialog.acting = true
      let params = {}
      Object.keys(this.dialog.field).map(key => {
        params[key] = this.dialog.field[key].value
      })

      let add = this.dialog.type === 'add'

      let req
      if (add) {
        req = this.$http.post('boom-center-product-service/sysAdmin/wx/media/platform', params)
      } else {
        params.id = this.dialog.id
        req = this.$http.put('boom-center-product-service/sysAdmin/wx/media/platform', params)
      }
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      req
        .then(res => {
          if (res) {
            this.getList()
            this.$message({
              message: `${add ? '添加' : '修改'}成功`,
              type: 'success'
            })
            this.dialog.show = false
          } else {
            this.$message.error(`${add ? '添加' : '修改'}失败`)
          }
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          this.dialog.acting = false
          loading.close()
        })
      // .finally(() => (this.dialog.acting = false))
    }
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
}
.media-platform-setting {
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
